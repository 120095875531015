<template>
    <div>
        <p class="text-lg">Customer Return Data</p>
        <hr />

        <div class="my-2">
            <label class="vs-input--label">Reference Code</label>
            <vs-input class="w-full" type="text" v-model="form.customer_return_data.reference_code"
                :disabled="form.isDetail == 1" />
        </div>

        <div class="my-2">
            <label class="vs-input--label">External Code</label>
            <vs-input class="w-full" type="text" :disabled="form.isDetail == 1"
                v-model="form.customer_return_data.external_code" />
        </div>

        <div class="my-2" v-if="form.so_types == 'with_so_reference'">
            <label class="vs-input--label">PPN Type</label>
            <SelectTax @data="setSelectTax" ref="selectTax" />
        </div>

        <div class="my-2" v-if="form.so_types == 'without_so_reference' && (form.isDetail == 1 || form.isEdit == true)">
            <label class="vs-input--label">PPN Type</label>
            <vs-input class="w-full" type="text" :disabled="form.isDetail == 1 || form.isEdit == true" v-model="form.return_line_item_data[0].taxName" />
        </div>

        <div class="my-2">
            <label class="vs-input--label">Reason Code</label>
            <SelectMasterReason :reasonGroup="'Customer Return'" @data="setSelectMasterReason"
                ref="selectMasterReason" />
        </div>

        <div class="my-2">
            <label class="vs-input--label">Customer Return Type</label>
            <SelectCustomerReturnType @data="setCustomerReturnType" ref="selectCustomerReturnType" :isGetData="false" />
        </div>

        <div class="my-2">
            <label class="vs-input--label">Territory Warehouse</label>
            <SelectTerritoryWarehouse ref="selectTerritoryWarehouse" @data="setSelectTerritoryWarehouse"
                :isDisabled="true" />
        </div>

        <div class="my-2">
            <label class="vs-input--label">Customer Return Date</label>
            <vs-input
                type="date"
                class="w-full"
                v-model="form.customer_return_data.customer_return_date"
                :disabled="form.isDetail == 1 || form.isEdit == true"
                :max="today"
            />
        </div>

        <div class="my-2">
            <label class="vs-input--label">Note</label>
            <vs-textarea class="w-full" v-model="form.customer_return_data.note"
                :disabled="form.isDetail == 1"></vs-textarea>
        </div>

        <!-- <div class="my-2">
      <label class="vs-input--label"
        >Attachment
        <small style="color: red"
          >(only: jpg, jpeg, png, pdf, doc, docx, xls, xlsx)</small
        >
      </label>
      <InputFile
        :isDisabled="form.isDetail == 1"
        :acceptedTypes="acceptedTypes"
        @data="setAttachment"
        setPathUpload="sales-return-v2"
        :isWithAPI="true"
        ref="inputFile"
      />
      <ul
        class="my-2 flex gap-2"
        v-show="
          !this.form.isEdit &&
          this.form.customer_return_data.attachments != null
        "
      >
        <li
          v-for="(item, index) in this.form.customer_return_data.attachments"
          :key="index"
        >
          <vs-button
            color="primary"
            type="filled"
            size="small"
            @click="downloadAttachment(item)"
          >
            {{ `Download ` + index }}
          </vs-button>
        </li>
      </ul>
    </div> -->

        <div class="my-2">
            <Attachments :isDisabled="form.isDetail == 1" @data="setAttachment" ref="inputAttachments" />
        </div>
    </div>
</template>

<script>
import SelectCustomerReturnType from "@/components/master/SelectCustomerReturnType.vue";
import SelectMasterReason from "@/components/master/SelectMasterReason.vue";
import SelectTerritoryWarehouse from "@/components/master/SelectTerritoryWarehouse.vue";
import InputFile from "@/components/master/InputFile.vue";
import SelectTax from "@/components/master/SelectTax.vue";
import Attachments from "@/components/attachments/index.vue";

export default {
    components: {
        SelectCustomerReturnType,
        SelectMasterReason,
        SelectTerritoryWarehouse,
        InputFile,
        SelectTax,
        Attachments,
    },
    props: {
        form: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {
            // jpg, jpeg, png, pdf, doc, docx, xls, xlsx
            acceptedTypes: [
                "image/jpeg",
                "image/png",
                "application/pdf",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ],
            today: new Date().toISOString().split("T")[0],
        };
    },
    mounted() {
        this.setDefaultCNReturnType();
        console.log("this.form", this.form);
        if (this.form.isEdit || this.form.isDetail == 1) {
            console.log("this.form.customer_return_data.tax_id (1a)", this.form.customer_return_data.tax_id)
            if (
                this.form.customer_return_data.tax_id != null &&
                this.form.so_types == "with_so_reference"
            ) {
                this.$refs.selectTax.setByID(this.form.customer_return_data.tax_id);
                console.log("this.form.customer_return_data.tax_id (1b)", this.form.customer_return_data.tax_id)
            }

            if (this.form.customer_return_data.reason_id != null) {
                this.$refs.selectMasterReason.setByID(
                    this.form.customer_return_data.reason_id
                );
            }

            if (this.form.customer_return_data.territory_warehouse_id != null) {
                this.$refs.selectTerritoryWarehouse.setParams({
                    id: this.form.customer_return_data.territory_warehouse_id,
                });
                this.$refs.selectTerritoryWarehouse.getWarehouseTerritory();
            }

            if (this.form.customer_return_data.attachments != null) {
                this.$refs.inputAttachments.setDefaultAttachment(
                    this.form.customer_return_data.attachments
                );
            }
        }
    },
    methods: {
        async setDefaultCNReturnType() {
            await this.$refs.selectCustomerReturnType.getData();
            await this.$refs.selectCustomerReturnType.setDefaultCNReturnType();
            await this.$refs.selectCustomerReturnType.setToggleDisabled();
        },
        async setSelectTax(data) {
            this.form.customer_return_data.tax_id = data;
        },
        async downloadAttachment(url) {
            window.open(url, "_blank");
        },
        async setSelectMasterReason(data) {
            this.form.customer_return_data.reason_code = data;
        },
        async setCustomerReturnType(data) {
            this.form.customer_return_data.customer_return_type = data;
        },
        async setAttachment(data) {
            this.form.customer_return_data.attachments = data;
        },
        async setSelectTerritoryWarehouse(data) {
            this.form.customer_return_data.territory_warehouse = data;
        },
    },
    watch: {
        "form.customer_invoice_data": {
            handler: function (val) {
                if (val) {
                    if (val.invoice.TaxID != 0) {
                        this.$refs.selectTax.setByID(val.invoice.TaxID);
                    }
                }
            },
            deep: true,
        },
        // "form.customer_return_data.territory_warehouse_id": {
        //   handler: function (val) {
        //     if (val) {
        //       this.$refs.selectTerritoryWarehouse.setParams({
        //         id: val,
        //       });
        //       this.$refs.selectTerritoryWarehouse.getWarehouseTerritory();
        //     }
        //   },
        // },
        // "form.customer_data.selected_customer": {
        "form.customer_data.pickup_from_ship_to": {
            handler: function (val) {
                let customerAddressID = 0;
                if (val != null) {
                    if (val.ID) {
                        if (val.territory_id != null) {
                            customerAddressID = val.territory_id;
                        }
                    }

                    if (customerAddressID != 0) {
                        this.$refs.selectTerritoryWarehouse.setParams({
                            territory_id: customerAddressID,
                        });
                        this.$refs.selectTerritoryWarehouse.getWarehouseTerritory();
                        // set territory warehouse default
                        if (this.form.so_types == "with_so_reference") {
                            this.$refs.selectTerritoryWarehouse.setIsDefaultOnly();
                        }
                        // this.$vs.notify({
                        //   title: "Error",
                        //   text: "Customer address territory id is empty",
                        //   color: "danger",
                        //   iconPack: "feather",
                        //   icon: "icon-alert-circle",
                        // });
                        // return;
                    }
                }
            },
        },
        "form.isDetail": {
            handler: function (val) {
                if (val) {
                    this.$refs.selectTax.setDisabled(true);
                    this.$refs.selectMasterReason.setDisabled(true);
                    this.$refs.selectCustomerReturnType.setDisabled(true);
                    this.$refs.selectTerritoryWarehouse.setDisabled(true);
                    this.$refs.inputFile.setDisabled(true);
                }
            },
        },
        "form.so_types": {
            handler: function (so_types) {
                if (so_types == "without_so_reference" && this.form.customer_invoice_data != null) {
                    this.$refs.selectTerritoryWarehouse.setNullData();
                }
            },
        },
        // "form.customer_return_data": {
        //   handler: function (val) {
        //     if (val.tax_id != null) {
        //       this.$refs.selectTax.setByID(val.tax_id);
        //     }
        //   },
        // },
        // "form.customer_return_data.reason_id": {
        //   handler: function (val) {
        //     if (val) {
        //       this.$refs.selectMasterReason.setByID(
        //         this.form.customer_return_data.reason_id
        //       );

        // this.$refs.selectCustomerReturnType.setByID(
        //   this.form.customer_return_data.customer_return_type_id
        // );
        //     }
        //   },
        // },
    },
};
</script>
