<template>
  <div>
    <p class="text-lg">Customer Invoice Data</p>
    <hr />

    <div class="my-2">
      <label class="vs-input--label">Customer Invoice ID</label>
      <CustomerInvoice
        @data="setCustomerInvoice"
        :isDisable="isDisabled"
        ref="searchCustomerInvoice"
      />
    </div>
    <div class="my-2">
      <label class="vs-input--label">Sales ID</label>
      <vs-input readonly class="w-full" v-model="sales_name" />
    </div>
  </div>
</template>

<script>
import CustomerInvoice from "@/components/master/CustomerInvoice.vue";
import { watch } from "vue";

// DTO customer invoice
var dtoCustomerInvoice = {
  invoiceID: null,
  salesID: null,
  customerIDs: [],
  itemUnitIDs: [],
  salesOrderLines: [],
  salesOrderID: null,
  salesOrderData: null,
};

// set dto customer invoice
function setDtoCustomerInvoice(data) {
  console.log("setDtoCustomerInvoice", data)
  if(data!=null){
    dtoCustomerInvoice.invoiceID = data.invoice.ID;
    dtoCustomerInvoice.salesID = data.sales.ID;
    dtoCustomerInvoice.customerIDs = [data.invoice.ClientID];
    dtoCustomerInvoice.invoice = data.invoice;
  
    // dtoCustomerInvoice.itemUnitIDs = data.item_unit_ids;
  
    // sales_order -> sales_order_lines
    dtoCustomerInvoice.invoiceLines = [];
    data.invoice.InvoiceLines.forEach((item2) => {
      dtoCustomerInvoice.invoiceLines.push(item2);
    });
  
    if (data.sales_order.length > 0) {
      dtoCustomerInvoice.salesOrderID = data.sales_order[0].ID;
      dtoCustomerInvoice.salesOrderData = data.sales_order[0];
    }
  
    return dtoCustomerInvoice;
  } else {
    return null
  }
}

export default {
  components: {
    CustomerInvoice,
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      sales_name: null,
      data: {
        sales: null,
        invoice: null,
        sales_order: [],
      },
    };
  },
  methods: {
    async setCustomerInvoice(data) {
      this.data = data;
      // console.log("this.data", this.data)
      this.sales_name = (data.sales_order.length > 0 && data.sales_order != null) ? `${data.sales_order[0].SalesPersonalCodeExternal} - ${data.sales_order[0].SalesPersonalName}` : `${data.sales.CodeExternal} - ${data.sales.Name}`;
      this.$emit("data", setDtoCustomerInvoice(data));
    },
  },
  mounted() {
    if (this.form.isEdit || this.form.isDetail) {
      this.$refs.searchCustomerInvoice.setCode(
        this.form.customer_invoice_data.invoice_code
      );
      this.isDisabled = true;
      this.sales_name = this.form.customer_invoice_data.sales_order_data == null ? this.form.customer_invoice_data.sales_id_name : this.form.customer_invoice_data.sales_order_data.SalesPersonalCodeExternal + " - " + this.form.customer_invoice_data.sales_order_data.SalesPersonalName;
    }
  },
  watch: {
    "form.so_types": {
      handler: function (so_types) {
        console.log("form.so_types", this.form.so_types)
        console.log("so_types", so_types)
        if (so_types == "without_so_reference" && this.form.customer_invoice_data != null) { 
          //set null all data
          this.$refs.searchCustomerInvoice.setCode(
            null
          );
          this.form.customer_invoice_data = {
            customer_invoice_id: null,
            sales_id: null,
            item_unit_ids: [],
            sales_order_lines: [],
            sales_order_id: null,
            invoice_lines: [],
            invoice_id: null,
            sales_order_data: null,
				  };
          this.form.customer_return_data.customer_ship_to = null;
          this.form.territory_warehouse = null;
          this.form.territory_warehouse_id = null;
          this.sales_name = null;
          this.data       = {
            sales: null,
            invoice: null,
            sales_order: [],
          };
        }
      },
    },
  }
};
</script>
