var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"text-lg"},[_vm._v("Customer Data")]),_c('hr'),(_vm.form.so_types == 'without_so_reference')?_c('div',{staticClass:"my-2"},[_c('label',{staticClass:"vs-input--label"},[_vm._v("Select Territory")]),_c('SelectTerritory',{ref:"selectTerritory",attrs:{"isDisable":_vm.form.isEdit || _vm.form.so_types == 'with_so_reference',"params":this.terr_params},on:{"data":_vm.setSelectTerritory}})],1):_vm._e(),_c('div',{staticClass:"my-2"},[_c('label',{staticClass:"vs-input--label"},[_vm._v("Pickup From / Ship To")]),_c('SelectCustomerAddress',{ref:"selectCustomerAddress",attrs:{"isDisabled":_vm.form.so_types == 'with_so_reference' ||
        _vm.form.isDetail == 1 ||
        _vm.form.isEdit,"formDatas":_vm.form},on:{"data":_vm.setSelectCustomerAddress}})],1),_c('div',{staticClass:"my-2"},[_c('label',{staticClass:"vs-input--label"},[_vm._v("Customer")]),_c('SelectCustomer',{ref:"selectCustomer",attrs:{"isWithoutSoRef":_vm.form.so_types == 'without_so_reference',"isDisabled":_vm.form.so_types == 'with_so_reference' ||
        _vm.form.isDetail == 1 ||
        _vm.form.isEdit},on:{"data":_vm.setSelectCustomer}})],1),_c('div',{staticClass:"my-2"},[_c('label',{staticClass:"vs-input--label"},[_vm._v("Customer Name")]),_c('vs-input',{staticClass:"w-full",attrs:{"readonly":"","disabled":_vm.form.isDetail == 1},model:{value:(_vm.form.customer_data.customer_name),callback:function ($$v) {_vm.$set(_vm.form.customer_data, "customer_name", $$v)},expression:"form.customer_data.customer_name"}})],1),_c('div',{staticClass:"my-2"},[_c('label',{staticClass:"vs-input--label"},[_vm._v("Customer Sold To")]),_c('SelectCustomerBillTo',{ref:"selectCustomerBillTo",attrs:{"isDisabled":_vm.form.so_types == 'with_so_reference' ||
        _vm.form.isDetail == 1 ||
        _vm.form.isEdit},on:{"data":_vm.setSelectCustomerBillTo}})],1),(_vm.form.so_types == 'without_so_reference')?_c('div',{staticClass:"my-2"},[_c('label',{staticClass:"vs-input--label"},[_vm._v("Select Salesman")]),_c('multiselect',{staticClass:"selectExample",attrs:{"options":_vm.salesPersonal.option,"multiple":false,"allow-empty":true,"max-height":120,"limit":3,"placeholder":"Type to search","loading":_vm.isLoadingSales,"track-by":"ID","custom-label":_vm.customLableSalesman,"disabled":_vm.form.isDetail == 1 || _vm.form.isEdit || _vm.form.so_types == 'with_so_reference'},on:{"search-change":_vm.handlerSearchSales,"input":_vm.onselectedSalesman},model:{value:(_vm.salesPersonal.selected),callback:function ($$v) {_vm.$set(_vm.salesPersonal, "selected", $$v)},expression:"salesPersonal.selected"}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }